
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import {ListDataSource} from "@/data/List/ListDataSource";
import {Filter} from "@/cms-services/filter";

@Component
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "priceCatalog",
  });

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Каталог цен",
        to: "/manage/price-catalogs/",
        exact: true,
      },
      {
        text: this.dataSource.model?.caption,
        disabled: true,
      },
    ];
  }

  cretePriceItem:any = {
    priceCatalogId:+this.$route.params.id,
    caption:""
  }

  priceListDataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
      filter: JSON.stringify([
        new Filter("catalogId", +this.$route.params.id)
      ])
    },
    className: "pricelist",
  });
}
